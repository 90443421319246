/* eslint-disable no-restricted-globals */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useMediaQuery } from 'react-responsive';

import { mobile } from '../../constants/breakpoints';
import {
  ContextMenu,
  ContextMenuLink,
  HeaderProfile,
  HeaderProfileName,
  HeaderWrapper,
  HeaderWrapperInner,
  HeaderWrapperInnerStyle,
  LogoStyle,
  LogoWrapper,
  NavbarLink
} from '../common/header/HeaderWrapper';
import Box from '../common/box/Box';
import Icon from '../common/icon/Icon';
import { colors } from '../constant';
import { LoaderWrap } from '../common/loader/Loader';
import { getProfile } from '../settings/endpoints';
import { LanguageSelector } from '../common/language-selector';

import { logOut } from './endpoints';
import TextAvatar from './TextAvatar';

export function Header() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [contextMenuDisplayed, setContextMenuDisplayed] = useState(false);
  const { logout, setAuthLoader } = useStoreActions(
    actions => actions.authentication
  );
  const { authLoader } = useStoreState(state => state.authentication);
  const { userData, firstAndLastName } = useStoreState(state => state.user);
  const { setUserData } = useStoreActions(actions => actions.user);
  const { setUsageDates } = useStoreActions(actions => actions.usage);
  const { theme } = useStoreState(actions => actions.theme);
  const isMobile = useMediaQuery({ query: `(max-width: ${mobile}px)` });

  const onHeaderProfileClick = e => {
    e.stopPropagation();
    setContextMenuDisplayed(prev => !prev);
  };

  const onSettingsClick = e => {
    setContextMenuDisplayed(prev => !prev);
    navigate('/settings/profile');
  };

  const onLogoutClick = e => {
    setAuthLoader(true);
    setUsageDates(null);
    logOut()
      .then(([res]) => {
        if (res?.success) {
          logout();
        }
      })
      .catch(() => {
        setAuthLoader(false);
      });
  };

  const hideContextMenu = () => {
    setContextMenuDisplayed(false);
  };
  const useOutsideClick = callback => {
    const ref = useRef();

    React.useEffect(() => {
      const handleClick = event => {
        callback();
      };

      document.addEventListener('click', handleClick);

      return () => {
        document.removeEventListener('click', handleClick);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return ref;
  };
  const ref = useOutsideClick(hideContextMenu);

  useEffect(() => {
    if (userData) {
      getProfile().then(([res]) => {
        if (res?.success) {
          setUserData({
            ...userData,
            FirstName: res.data.firstName,
            LastName: res.data.lastName
          });
        }
      });
    }
  }, []);

  return (
    <HeaderWrapper>
      <HeaderWrapperInner width="100%" padding={isMobile ? '0 10px' : '0 40px'}>
        <HeaderWrapperInnerStyle>
          <LogoWrapper isMobile={isMobile}>
            <LogoStyle
              src={theme.headerLogo}
              display={theme.headerLogo ? 'block' : 'none'}
            />
          </LogoWrapper>

          <NavbarLink
            isMobile={isMobile}
            exact="true"
            activeclassname="active"
            to="/buckets"
          >
            {t('buckets')}
          </NavbarLink>
          <NavbarLink
            isMobile={isMobile}
            exact="true"
            activeclassname="active"
            to="/usage"
          >
            {t('usage')}
          </NavbarLink>
        </HeaderWrapperInnerStyle>

        <HeaderWrapperInnerStyle>
          <LanguageSelector menuPortalTarget={null} />
          <HeaderProfile ref={ref}>
            {!localStorage.getItem('refreshTokenExpired') && (
              <Box
                pointer="true"
                display="flex"
                alignitems="center"
                onClick={onHeaderProfileClick}
              >
                <HeaderProfileName isMobile={isMobile}>
                  {userData.FirstName
                    ? userData.FirstName
                    : firstAndLastName?.split(' ')[0]}
                </HeaderProfileName>
                <TextAvatar
                  round="50px"
                  bgColor={colors.gray}
                  textColor={theme.themes?.colorPrimary}
                  size={isMobile ? 25 : 35}
                  showTextAvatar={true}
                  name={
                    firstAndLastName
                      ? firstAndLastName.split(' ')[0]
                      : userData.FirstName ?? ''
                  }
                  lastName={userData.LastName ?? ''}
                />
              </Box>
            )}
            <ContextMenu
              display={contextMenuDisplayed ? 'flex' : 'none'}
              onClick={e => e.stopPropagation()}
            >
              <ContextMenuLink onClick={onSettingsClick}>
                <Icon icon="settings" mr="15px" /> {t('settings')}
              </ContextMenuLink>
              <ContextMenuLink onClick={onLogoutClick}>
                <Icon icon="logout" mr="15px" />
                {t('logOut')}
              </ContextMenuLink>
            </ContextMenu>
          </HeaderProfile>
        </HeaderWrapperInnerStyle>
      </HeaderWrapperInner>
      {authLoader && (
        <Box
          background="transparent"
          minwidth="100vw"
          minheightsize="100vh"
          position="fixed"
        >
          <LoaderWrap display={authLoader} />
        </Box>
      )}
    </HeaderWrapper>
  );
}

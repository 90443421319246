/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from 'easy-peasy';

import {
  formatToYearMonthDayWithTimeZoneOffset,
  substractDayIfIUTC
} from '../../utils/format.dates.utils';
import { ENDPOINTS } from '../../constants/endpoints';
import { allColumns } from '../../utils/columns';
import Box from '../common/box/Box';
import Text from '../common/text/Text';
import TableComponent from '../common/table-component';
import { colors } from '../constant';
import { LoaderWrap } from '../common/loader/Loader';
import { subtractDaysFromDate } from '../common/range-picker';

import DataCards from './component/DataCards';
import ExportUsage from './component/ExportUsage';
import { columns } from './helpers';
import { readTable, readUsageDates } from './endpoint';

const UsageList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [exportLoading, setExportLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [totalSize, setTotalSize] = useState(10);
  const [date, setDate] = useState([null, null]);
  const [showExportModal, setShowExportModal] = useState(false);
  const { setUsageDates } = useStoreActions(actions => actions.usage);
  const { usageDates } = useStoreState(actions => actions.usage);
  const { setUsageLastSync } = useStoreActions(actions => actions.usage);
  const { usageLastSync } = useStoreState(actions => actions.usage);
  const [displayLoader, setDisplayLoader] = useState(false);

  useEffect(() => {
    if (!usageDates?.[1]) {
      readUsageDates()
        .then(([res]) => {
          if (res?.data?.data?.endTime) {
            setUsageLastSync(res?.data?.data.endTime);
            setDate([
              subtractDaysFromDate(new Date(res?.data?.data?.endTime)),
              subtractDaysFromDate(new Date(res?.data?.data?.endTime), 0)
            ]);
          } else {
            setDate([moment().subtract(1, 'days')._d, moment()._d]);
          }
        })
        .catch(err => {
          //silent err
        });
    }
  }, []);

  useEffect(() => {
    if (usageDates?.[1]) setDate(usageDates);
  }, [usageDates]);

  useEffect(() => {
    setDisplayLoader(true);
    if (date?.[1]) {
      readTable(
        ENDPOINTS.GET_USAGES.replace(':page:', page)
          .replace(':size:', size)
          .replace(
            ':range:',
            date[1]
              ? `endTime=${formatToYearMonthDayWithTimeZoneOffset(
                  date[0]
                )},${formatToYearMonthDayWithTimeZoneOffset(date[1])}`
              : ''
          )
      ).then(([res]) => {
        const data = res?.data?.data?.items;
        setRows({ ...res?.data, data });
        setTotalSize(res?.data?.data?.total);
        setDisplayLoader(false);
      });
    }
  }, [page, size, date[1]]);

  const disabledDate = d => {
    return moment(d).isAfter(
      moment(subtractDaysFromDate(usageLastSync, 0) || date[1]),
      'day'
    );
  };

  return (
    <Box p="50px 30px 30px" bgcolor={colors.softGray}>
      <DataCards date={date} />
      <Box relative="true" p="16px 0 0 0">
        <Box p="30px" bgcolor="white">
          <TableComponent
            lastSync={usageLastSync}
            size={size}
            page={page}
            columns={allColumns(columns, rows?.data)}
            rows={rows?.data}
            dateValue={date}
            totalSize={totalSize}
            onPageChange={e => setPage(e)}
            onSizeChange={e => setSize(e)}
            dateOnChange={e => {
              setPage(0);
              setDate(e);
              setUsageDates(e);
            }}
            exportOnClick={() => setShowExportModal(true)}
            cleanable={false}
            searchable={false}
            titleTable={true}
            hideFooter={!rows?.data?.length}
            disabledDate={date => disabledDate(date)}
            onRowClick={(params, event) => {
              if (!event.ignore) {
                navigate(
                  `${
                    params.id
                  }/usage-details/${formatToYearMonthDayWithTimeZoneOffset(
                    substractDayIfIUTC(params.row.startTime)
                  )}~${formatToYearMonthDayWithTimeZoneOffset(
                    substractDayIfIUTC(params.row.endTime)
                  )}`
                );
              }
            }}
            displayLoader={displayLoader}
            components={{
              LoadingOverlay: () => (
                <Box heightsize="130px" p="40px 20px">
                  <LoaderWrap display={true} />
                </Box>
              ),
              NoResultsOverlay: () =>
                columns.length <= 0 ? (
                  <Text fontSize="14px" mt="10px" color={colors.bodyColor}>
                    {t('noResultsOverlayMessage')}
                  </Text>
                ) : (
                  <></>
                )
            }}
            title={t('accountUsage')}
            tooltip={[
              t('accountUsageIsCalculatedPerDay'),
              <br key="br1" />,
              <br key="br2" />,
              t('newAccountsWillNotSeeData')
            ]}
          />
        </Box>
        <ExportUsage
          exportLoading={exportLoading}
          setExportLoading={setExportLoading}
          showExportModal={showExportModal}
          setShowExportModal={setShowExportModal}
          date={date}
        />
      </Box>
    </Box>
  );
};

export default UsageList;

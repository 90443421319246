/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import * as moment from 'moment/moment';
import { saveAs } from 'file-saver';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import { useTranslation } from 'react-i18next';

import { formatToYearMonthDayWithTimeZoneOffset } from '../../utils/format.dates.utils';
import { formatStorageValue } from '../../utils/format-storage-values.utils';
import { colors } from '../constant';
import Box from '../common/box/Box';
import Text from '../common/text/Text';
import Icon from '../common/icon/Icon';
import Line from '../common/line/Line';
import useDebounce from '../../hooks/use-debounce';
import { ENDPOINTS } from '../../constants/endpoints';
import { allColumns } from '../../utils/columns';
import { ExportModal } from '../common/exportmodal/ExportModal';
import { CardSimple } from '../common/card/CardSimple';
import { LoaderWrap } from '../common/loader/Loader';
import TableComponent from '../common/table-component';

import { columns } from './helpersUsageDetails';
import {
  exportElasticServiceDetailsFile,
  readUsageDataCardsDetails,
  readUsageDetailsFilters,
  readUsageDetailsTable
} from './endpoint';

const FILTERS = [{ key: 'region' }, { key: 'status' }];
export default function UsageDetails() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { dateTime } = useParams();
  const [exportLoading, setExportLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [totalSize, setTotalSize] = useState(10);
  const [date, setDate] = useState([null, null]);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState('');
  const [filterBy, setFilterBy] = useState('');
  const [showExportModal, setShowExportModal] = useState(false);
  const { theme } = useStoreState(actions => actions.theme);
  const filterWith = ENDPOINTS.FILTER_WITH;
  const [displayLoader, setDisplayLoader] = useState(false);
  const [dataCardDetails, setDataCardDetails] = useState({
    totalAPICalls: null,
    totalEgress: null,
    totalIngress: null,
    totalObjects: null,
    totalStorage: null
  });

  useEffect(() => {
    readUsageDataCardsDetails([
      formatToYearMonthDayWithTimeZoneOffset(d2),
      formatToYearMonthDayWithTimeZoneOffset(d2)
    ])
      .then(([res]) => {
        setDataCardDetails(res?.data.data);
      })
      .catch(() => {
        // silent err
      });
  }, [date?.[1]]);

  const getUsageDetailsTable = () => {
    setDisplayLoader(true);
    let createFilters = '';
    if (filterBy.length > 0) {
      filterBy.map((item, index) => {
        // If item.key is equal to region send it to backend as customRegion
        const itemKey = item.key !== 'region' ? item?.key : 'customRegion';
        createFilters += `${index === 0 ? '&filter=' : 'filter='}${itemKey}=${
          item.value
        }${filterBy.length - 1 !== index ? '&' : ''}`;
      });
    }
    readUsageDetailsTable(
      ENDPOINTS.GET_USAGES_DETAILS.replace(':page:', page)
        .replace(':size:', size)
        .concat(
          search?.length > 0 ? '&search=' + encodeURIComponent(search) : ''
        )
        .concat(
          d2
            ? `&range=endTime=${formatToYearMonthDayWithTimeZoneOffset(
                d2
              )},${formatToYearMonthDayWithTimeZoneOffset(d2)}`
            : ''
        )
        .concat(createFilters?.length > 0 ? createFilters : '')
    ).then(([res]) => {
      const data = res?.data?.data?.items;
      setRows(data);
      setTotalSize(res?.data?.data?.total);
      setFilters(FILTERS);
      setDisplayLoader(false);
    });
  };

  const debouncedSearchTerm = useDebounce(search, 500);

  useEffect(() => {
    getUsageDetailsTable();
  }, [page, size, date[1], debouncedSearchTerm]);

  async function exportDetailsFileSave(event) {
    setExportLoading(true);
    let extension = event;
    if (event === 'excel') {
      extension = 'xlsx';
    }

    let createFilters = '';
    if (filterBy.length > 0) {
      filterBy.map((item, index) => {
        // If item.key is equal to region send it to backend as customRegion
        const itemKey = item.key !== 'region' ? item?.key : 'customRegion';
        createFilters += `${index === 0 ? '?&filter=' : 'filter='}${itemKey}=${
          item.value
        }${filterBy.length - 1 !== index ? '&' : ''}`;
      });
    }
    exportElasticServiceDetailsFile(
      ENDPOINTS.GET_USAGES_DETAILS_EXPORT(createFilters)
        .replace(':type', event)
        .concat(
          search?.length > 0
            ? `${createFilters?.length ? '' : '?'}&search=` + search
            : ''
        )
        .concat(
          d2
            ? `${
                !!search.length || !!createFilters?.length ? '' : '?'
              }&range=endTime=${formatToYearMonthDayWithTimeZoneOffset(
                d2
              )},${formatToYearMonthDayWithTimeZoneOffset(d2)}`
            : ''
        )
    ).then(([res, error]) => {
      if (error) {
        // silent err
      }
      setShowExportModal(false);
      setExportLoading(false);
      let fileName = res?.headers['file-name'];
      if (!fileName) {
        fileName = 'export';
      }
      saveAs(new Blob([res.data]), `${fileName}.${extension}`);
    });
  }

  function exportOnClick(event) {
    setShowExportModal(true);
  }

  let d1 = dateTime.split('~')[0];
  let d2 = dateTime.split('~')[1];

  let titleDate;
  if (dateTime?.includes('~')) {
    d1 = `${moment(d1).format('DD-MMM-yyyy')} ${moment(new Date(d1)).format(
      'hh:mm A'
    )}`;
    d2 = `${moment(d2).format('DD-MMM-yyyy')} ${moment(new Date(d2)).format(
      'hh:mm A'
    )}`;
    titleDate = `${d1} - ${d2}`;
  } else {
    navigate('/usage');
  }

  // Get filter from table header / multi-filter component.
  useEffect(() => {
    if (filterBy.length > 0) {
      let createFilters = '';
      filterBy.map((item, index) => {
        // If item.key is equal to region send it to backend as customRegion
        const itemKey = item.key !== 'region' ? item?.key : 'customRegion';
        createFilters += `filter=${itemKey}=${item.value}${
          filterBy.length - 1 !== index ? '&' : ''
        }`;
      });
      readUsageDetailsFilters(
        createFilters,
        page,
        size,
        search.concat(
          d2
            ? `&range=endTime=${formatToYearMonthDayWithTimeZoneOffset(
                d2
              )},${formatToYearMonthDayWithTimeZoneOffset(d2)}`
            : ''
        )
      ).then(([res]) => {
        setRows(res?.data?.data?.items);
        setTotalSize(res?.data?.data?.total);
      });
    } else {
      getUsageDetailsTable();
    }
  }, [filterBy]);

  const handleArrowClick = () => {
    navigate('/usage', { replace: true });
  };

  return (
    <>
      <Box p="20px 30px" bgcolor={colors.softGray}>
        <Box
          onClick={handleArrowClick}
          display="inline-flex"
          alignitems="center"
          mb="20px"
          pointer="true"
        >
          <Icon icon="arrow_left"></Icon>
          <Text fontSize="18px" color={colors.black} fontWeight="500">
            {titleDate}
          </Text>
        </Box>

        <Box
          display="grid"
          gridcolumn="repeat(auto-fit, minmax(250px, 1fr) )"
          gap="20px"
          mb="30px"
        >
          {/*<button onClick={() => changeCardStatusNumber()}></button>*/}
          <CardSimple
            style={{ width: '100%' }}
            formattedValue={
              typeof dataCardDetails?.totalStorage === 'number'
                ? formatStorageValue(dataCardDetails?.totalStorage)
                : '--'
            }
            text={t('totalStorage')}
            measuringUnit="TB"
            message={t('totalStorageMessage')}
          >
            <Line
              color={theme.themes?.colorPrimary}
              absolute="true"
              t="0"
              l="0"
              borderRadius="4px 4px 0 0"
              borderWidth="3px"
            />
          </CardSimple>

          <CardSimple
            style={{ width: '100%' }}
            mainNumber={dataCardDetails?.totalObjects
              ?.toFixed(1)
              ?.toLocaleString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            text={t('totalObjects')}
            message={t('totalObjectsMessage')}
          >
            <Line
              color={theme.themes?.colorPrimary}
              absolute="true"
              t="0"
              l="0"
              borderRadius="4px 4px 0 0"
              borderWidth="3px"
            />
          </CardSimple>

          <CardSimple
            style={{ width: '100%' }}
            mainNumber={dataCardDetails?.totalAPICalls
              ?.toFixed(1)
              ?.toLocaleString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            text={t('apiCalls')}
            message={t('apiCallsMessage')}
          >
            <Line
              color={theme.themes?.colorPrimary}
              absolute="true"
              t="0"
              l="0"
              borderRadius="4px 4px 0 0"
              borderWidth="3px"
            />
          </CardSimple>

          <CardSimple
            style={{ width: '100%' }}
            formattedValue={
              typeof dataCardDetails?.totalEgress === 'number'
                ? formatStorageValue(dataCardDetails?.totalEgress)
                : '--'
            }
            text={t('egress')}
            message={t('egressMessage')}
          >
            <Line
              color={theme.themes?.colorPrimary}
              absolute="true"
              t="0"
              l="0"
              borderRadius="4px 4px 0 0"
              borderWidth="3px"
            />
          </CardSimple>

          <CardSimple
            style={{ width: '100%' }}
            formattedValue={
              typeof dataCardDetails?.totalIngress === 'number'
                ? formatStorageValue(dataCardDetails?.totalIngress)
                : '--'
            }
            text={t('ingress')}
            message={t('ingressMessage')}
          >
            <Line
              color={theme.themes?.colorPrimary}
              absolute="true"
              t="0"
              l="0"
              borderRadius="4px 4px 0 0"
              borderWidth="3px"
            />
          </CardSimple>
        </Box>
        <Box relative="true">
          <Box p="30px" bgcolor="white">
            <TableComponent
              className="not-highlight"
              size={size}
              page={page}
              columns={allColumns(columns, rows?.length ? [...rows] : [])}
              rows={rows?.length ? [...rows] : []}
              dateValue={date}
              searchValue={search}
              totalSize={totalSize}
              onPageChange={e => setPage(e)}
              onSizeChange={e => setSize(e)}
              searchOnChange={e => setSearch(e)}
              setSearch={setSearch}
              filterOnChange={e => setFilterBy(e)}
              dateOnChange={e => setDate(e)}
              exportOnClick={exportOnClick}
              display={false}
              searchable={true}
              titleTable={false}
              hideFooter={!rows?.length}
              filters={filters}
              filterWith={filterWith}
              displayLoader={displayLoader}
              components={{
                LoadingOverlay: () => (
                  <Box heightsize="130px" p="40px 20px">
                    <LoaderWrap display={true} />
                  </Box>
                ),
                NoResultsOverlay: () =>
                  columns.length <= 0 ? (
                    <Text fontSize="14px" mt="10px" color={colors.bodyColor}>
                      {t('noResultsOverlayMessage')}
                    </Text>
                  ) : (
                    <></>
                  )
              }}
              onRowClick={({ row }, event) => {
                if (!event.ignore) {
                  navigate(
                    '/usage/bucket/'
                      .concat(row?.name)
                      .concat(`/${row?.bucket_num}`)
                  );
                }
              }}
            />
          </Box>

          <ExportModal
            isLoading={exportLoading}
            open={showExportModal}
            close={() => setShowExportModal(false)}
            onSubmit={e => exportDetailsFileSave(e)}
          />
        </Box>
      </Box>
    </>
  );
}

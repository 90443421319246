import React from 'react';
import styled from 'styled-components';

import { colors } from '../../constant';
import { Spinner } from '../spinner';
import { bgcolor, bordercolor, color, cursor } from '../../properties';

const StyledButton = styled.button`
  /* Adapt the colors based on primary prop */
  display: ${props => (props.display ? props.display : 'initial')};
  background: ${props =>
    props.primary ? ({ theme }) => theme?.themes?.colorPrimary : 'white'};
  color: ${props => (props.primary ? colors.white : colors.bodyColor)};
  min-height: ${props => (props.minHeight ? props.minHeight : '40px')};
  font-weight: ${props => props.fontWeight || 500};
  font-size: 14px;
  padding: ${props =>
    props.isMobile ? '5px 10px' : props.padding || '5px 30px'};
  border: 1px solid
    ${props =>
      props.cancel || props.primary
        ? 'transparent'
        : props.outlined
        ? colors.lightGray
        : ({ theme }) => theme.themes?.colorPrimary};
  border-radius: ${props => props.borderRadius || '4px'};
  transition: 0.3s all ease-in-out;
  margin: ${props => props.m};
  margin-right: ${props => props.mr};
  margin-top: ${props => props.mt};
  margin-left: ${props => props.ml};
  width: ${props => props.w};
  min-width: ${props => props.minwidth};
  max-width: ${props => props.maxwidth};
  font-family: 'Roboto';

  &:hover {
    box-shadow: 0 12px 16px -10px ${({ theme }) =>
      theme.themes?.hoverColor ? 'none' : theme.themes?.colorPrimary};
    color: ${({ theme }) =>
      theme.themes?.hoverColor
        ? 'white'
        : theme.themes?.colorPrimary
        ? colors.white
        : colors.bodyColor};
    background-color: ${({ theme }) =>
      theme.themes?.hoverColor ??
      (theme.themes?.colorPrimary ? theme.themes?.colorPrimary : 'white')};
    border-color: ${props =>
      props.hoverColor ??
      (props.cancel || props.primary
        ? 'transparent'
        : props.outlined
        ? colors.lightGray
        : ({ theme }) => theme.themes?.colorPrimary)};

    svg,
    path {
      fill: ${({ theme }) =>
        theme.themes?.hoverColor
          ? 'white'
          : theme.themes?.colorPrimary
          ? colors.white
          : colors.bodyColor};
    }
  }

  &.delete {
    border: 1px solid transparent;

    &:hover {
      background-color: ${colors.red};
      border-color: transparent;
      color: ${colors.white} !important;
      box-shadow: 0 12px 16px -10px ${colors.red};
    }

    &.delete-with-border {
      border: 1px solid ${colors.lightGray};

      &:hover {
        border-color: ${colors.red};
      }
    }

    &.delete-modal {
      &:hover {
        background: ${colors.softGray};
        color: ${colors.bodyColor};
      }
    }
  }

  &.cancel {
    background-color: transparent;
    border: none;

    &:hover {
      color: ${colors.bodyColor};
      box-shadow: 0 12px 16px -10px ${colors.bodyColor};
    }

    &.no-pad {
      padding: 10px 0;
      &:hover {
        box-shadow: none;
      }
    }
  }

  &:disabled {
    background: ${colors.softGray};
    color: ${colors.bodyColor} !important;
    cursor: no-drop;
    border: 1px solid transparent;

    &:hover {
      background: ${colors.softGray};
      color: ${colors.bodyColor} !important;
      box-shadow: none;

      svg,
      path {
        fill: ${props => props.disabledColor};
      }
    }
    border: ${props =>
      props.disabledColor && `1px solid ${props.disabledColor}`};
  }

  ${({ center }) =>
    center && 'display: flex; justify-content: center; align-items: center;'}
  ${bgcolor}
    ${bordercolor}
    ${bordercolor}
    ${color}
    ${cursor}
`;

const Button = passedProps => {
  if (passedProps?.isLoading)
    return (
      <StyledButton
        {...passedProps}
        center
        onClick={() => {}}
        type="button"
        cursor="no-drop"
      >
        <Spinner minHeight={passedProps?.minHeight} />
      </StyledButton>
    );

  return <StyledButton {...passedProps} />;
};

export default Button;

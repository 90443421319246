import React from 'react';
import { useTranslation } from 'react-i18next';

import { SpanFlex } from '../../helpers';
import { colors } from '../../../../constant';
import Title from '../../../../common/title/Title';
import LightTooltip from '../../../../common/tooltip/Tooltip';
import Icon from '../../../../common/icon/Icon';

const AccessKeysHeader = () => {
  const { t } = useTranslation();

  return (
    <SpanFlex>
      <Title fontWeight="500" fontSize="18px" color={colors.black}>
        {t('accessKeys')}
      </Title>
      <LightTooltip title={t('accessKeysTooltip')} placement={'bottom-start'}>
        <SpanFlex>
          <Icon icon="info_icon" ml="5px" width="11px" />
        </SpanFlex>
      </LightTooltip>
    </SpanFlex>
  );
};

export default AccessKeysHeader;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useMediaQuery } from 'react-responsive';

import { mobile } from '../../../constants/breakpoints';
import hasPermission from '../../../security/Permission';
import { showToast } from '../../../hooks/show-toast';

import Box from '../../common/box/Box';
import Line from '../../common/line/Line';
import Title from '../../common/title/Title';
import Button from '../../common/button/Button';
import TextAvatar from '../../header/TextAvatar';
import CheckboxWrapper from '../../common/checkbox/CheckboxWrapper';
import Input, { ErrorMessage } from '../../common/input/Input';
import { colors } from '../../constant';
import { LabelInput } from '../../common/label/LabelInput';
import { LoaderWrap } from '../../common/loader/Loader';

import { getProfile, updateProfile } from '../endpoints';

import ChangePassword from './changePassword/ChangePassword';
import MultiFactorAuth from './multiFactorAuth/MultiFactorAuth';

const ProfileDetails = () => {
  const { t } = useTranslation();
  const isSsoUser = JSON.parse(localStorage.getItem('userIsSso'));
  const { setNewUser } = useStoreActions(actions => actions.authentication);
  const { userData } = useStoreState(state => state.user);
  const { setUserData } = useStoreActions(actions => actions.user);
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
    reset
  } = useForm({ mode: 'onChange' });

  const canReadProfilePerm = hasPermission('oem_profile', 'read');
  const canUpdateProfilePerm = hasPermission('oem_profile', 'update');

  const [buttonLoading, setButtonLoading] = useState(false);
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [userGroups, setUserGroups] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [active, setActive] = useState(false);
  const [image, setImage] = useState('');
  const [displayLoader, setDisplayLoader] = useState(false);
  const [updateDisabled, setUpdateDisabled] = useState(true);
  const [profileData, setProfileData] = useState(true);
  const isMobile = useMediaQuery({ query: `(max-width: ${mobile}px)` });

  const setData = data => {
    setId(data?.id ?? '');
    setName(data?.firstName ?? '');
    setLastName(data?.lastName ?? '');
    setEmail(data?.email ?? '');
    setUserName(data?.username ?? '');
    setUserGroups(data?.groups ?? '');
    setUserRole(data?.groups?.map(g => g.name).join(', ') ?? '');
    setActive(data?.active ?? false);
    setImage(data?.image ?? '');
  };

  useEffect(() => {
    setDisplayLoader(true);
    getProfile().then(([res]) => {
      setDisplayLoader(false);
      setUpdateDisabled(false);
      if (res.success) {
        setProfileData(res.data);
        setData(res.data);
        reset();
      } else {
        if (res?.code || res?.Code !== 'ErrCcc0045')
          showToast(
            {
              message: res?.code || res?.Code,
              params: res?.params || null,
              success: res?.success
            },
            'error'
          );
      }
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      profileData.email?.toLowerCase() === getValues().email?.toLowerCase() &&
      profileData.firstName === getValues().firstName &&
      profileData.lastName === getValues().lastName &&
      profileData.username?.toLowerCase() ===
        getValues().userName?.toLowerCase()
    ) {
      setUpdateDisabled(true);
    } else {
      setUpdateDisabled(false);
    }
  });

  const onChangeName = event => {
    setName(event.target.value);
  };

  const onChangeLastName = event => {
    setLastName(event.target.value);
  };

  const onChangeEmail = event => {
    setEmail(event.target.value);
  };

  const onChangeUserName = event => {
    setUserName(event.target.value);
  };

  const handleUpdate = () => {
    setButtonLoading(true);
    const data = {
      id,
      firstName: name,
      lastName,
      email,
      userName,
      groups: userGroups
    };

    updateProfile(data).then(([res]) => {
      if (res?.code || res?.Code !== 'ErrCcc0045')
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
      if (res.success) {
        setData(res.data);
        setProfileData(res.data);
        // setUser({firstName: res.data.firstName, lastName: res.data.lastName});
        setNewUser({
          firstName: res.data.firstName,
          lastName: res.data.lastName
        });
        setUserData({
          ...userData,
          FirstName: res.data.firstName,
          LastName: res.data.lastName
        });
      }
      setButtonLoading(false);
    });
  };

  const emailRegex = /^$|^(^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-][a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$)?$/;

  return (
    <Box>
      <Box display={displayLoader ? 'none' : 'block'}>
        <form onSubmit={handleSubmit(handleUpdate)}>
          {canReadProfilePerm && (
            <Box bgcolor={colors.white} p="30px">
              <Box
                display="flex"
                alignitems="center"
                justifycontent="space-between"
              >
                <Title fontSize="18px" fontWeight="500" color={colors.black}>
                  {t('profile')}
                </Title>
                {canUpdateProfilePerm && (
                  <Button
                    isLoading={buttonLoading}
                    primary
                    type={'submit'}
                    disabled={!isValid || updateDisabled || isSsoUser}
                  >
                    {t('update')}
                  </Button>
                )}
              </Box>

              <Line m="20px 0 40px" />

              <ProfileContainer isMobile={isMobile}>
                <Box>
                  <ProfileImg
                    src={image}
                    display={image && image.length > 0 ? 'initial' : 'none'}
                  ></ProfileImg>
                  <TextAvatar
                    bgColor={colors.softGray}
                    size={190}
                    showTextAvatar={!image || image.length === 0}
                    round="0"
                    name={name}
                    lastName={lastName}
                  />

                  <Box
                    bgcolor={colors.softGray}
                    center="true"
                    minheightsize="40px"
                    borderradius="4px"
                    mt="16px"
                  >
                    <CheckboxWrapper cursor={isSsoUser && 'not-allowed'}>
                      <input
                        disabled={isSsoUser}
                        type="checkbox"
                        id="active-input"
                        checked={active}
                        readOnly
                      />
                      <LabelInput id="region-label" htmlFor="active-input">
                        {t('active')}
                      </LabelInput>
                    </CheckboxWrapper>
                  </Box>
                </Box>

                <InputsContainer isMobile={isMobile}>
                  <div
                    className={`input-wrapper ${
                      isSsoUser ? 'input-wrapper-disabled' : ''
                    }`}
                  >
                    <Input
                      disabled={isSsoUser}
                      size="0"
                      width="100%"
                      fontSize="14px"
                      name="firstName"
                      value={name}
                      marginBottom="20px"
                      className={errors.firstName ? 'not-valid' : ''}
                      {...register('firstName', {
                        onChange: onChangeName,
                        minLength: 2,
                        maxLength: 50,
                        required: true,
                        pattern: /^\S.*$/g
                      })}
                    />

                    <label
                      htmlFor="firstName"
                      className={name.length > 0 ? 'filledField' : ''}
                    >
                      {t('firstName')}*
                    </label>
                    {errors.firstName?.type === 'minLength' && (
                      <ErrorMessage className="error-message--wrap">
                        {t('firstNameMinLength')}
                      </ErrorMessage>
                    )}
                    {errors.firstName?.type === 'maxLength' && (
                      <ErrorMessage className="error-message--wrap">
                        {t('firstNameMaxLength')}
                      </ErrorMessage>
                    )}
                    {errors.firstName?.type === 'required' && (
                      <ErrorMessage className="error-message--wrap">
                        {t('firstNameRequired')}
                      </ErrorMessage>
                    )}
                    {errors.firstName?.type === 'pattern' && (
                      <ErrorMessage className="error-message--wrap">
                        {t('firstNamePattern')}
                      </ErrorMessage>
                    )}
                  </div>

                  <div
                    className={`input-wrapper ${
                      isSsoUser ? 'input-wrapper-disabled' : ''
                    }`}
                  >
                    <Input
                      disabled={isSsoUser}
                      size="0"
                      width="100%"
                      fontSize="14px"
                      name="lastName"
                      marginBottom="20px"
                      value={lastName}
                      className={errors.lastName ? 'not-valid' : ''}
                      {...register('lastName', {
                        onChange: onChangeLastName,
                        minLength: 2,
                        maxLength: 50,
                        required: true,
                        pattern: /^\S.*$/g
                      })}
                    />

                    <label
                      htmlFor="lastName"
                      className={lastName.length > 0 ? 'filledField' : ''}
                    >
                      {t('lastName')}*
                    </label>
                    {errors.lastName?.type === 'minLength' && (
                      <ErrorMessage className="error-message--wrap">
                        {t('lastNameMinLength')}
                      </ErrorMessage>
                    )}
                    {errors.lastName?.type === 'maxLength' && (
                      <ErrorMessage className="error-message--wrap">
                        {t('lastNameMaxLength')}
                      </ErrorMessage>
                    )}
                    {errors.lastName?.type === 'required' && (
                      <ErrorMessage className="error-message--wrap">
                        {t('lastNameRequired')}
                      </ErrorMessage>
                    )}
                    {errors.lastName?.type === 'pattern' && (
                      <ErrorMessage className="error-message--wrap">
                        {t('lastNamePattern')}
                      </ErrorMessage>
                    )}
                  </div>

                  <div
                    className={`input-wrapper ${
                      isSsoUser ? 'input-wrapper-disabled' : ''
                    }`}
                  >
                    <Input
                      disabled={isSsoUser}
                      size="0"
                      width="100%"
                      fontSize="14px"
                      name="email"
                      marginBottom="20px"
                      value={email}
                      className={errors.email ? 'not-valid' : ''}
                      {...register('email', {
                        onChange: onChangeEmail,
                        required: true,
                        pattern: {
                          value: emailRegex
                        }
                      })}
                    />

                    <label
                      htmlFor="email"
                      className={email.length > 0 ? 'filledField' : ''}
                    >
                      {t('email')}*
                    </label>
                    {errors.email?.type === 'required' && (
                      <ErrorMessage className="error-message--wrap">
                        {t('emailRequired')}
                      </ErrorMessage>
                    )}
                    {errors.email?.type === 'pattern' && (
                      <ErrorMessage className="error-message--wrap">
                        {t('emailPattern')}
                      </ErrorMessage>
                    )}
                  </div>

                  <div
                    className={`input-wrapper ${
                      isSsoUser ? 'input-wrapper-disabled' : ''
                    }`}
                  >
                    <Input
                      disabled={isSsoUser}
                      size="0"
                      width="100%"
                      fontSize="14px"
                      name="userName"
                      marginBottom="20px"
                      value={userName}
                      className={errors.userName ? 'not-valid' : ''}
                      {...register('userName', {
                        onChange: onChangeUserName,
                        minLength: 3,
                        maxLength: 200,
                        required: true,
                        pattern: /^\S.*$/g
                      })}
                    />

                    <label
                      htmlFor="userName"
                      className={userName.length > 0 ? 'filledField' : ''}
                    >
                      {t('userName')}*
                    </label>
                    {errors.userName?.type === 'minLength' && (
                      <ErrorMessage className="error-message--wrap">
                        {t('userNameMinLength')}
                      </ErrorMessage>
                    )}
                    {errors.userName?.type === 'maxLength' && (
                      <ErrorMessage className="error-message--wrap">
                        {t('userNameMaxLength')}
                      </ErrorMessage>
                    )}
                    {errors.userName?.type === 'required' && (
                      <ErrorMessage className="error-message--wrap">
                        {t('userNameRequired')}
                      </ErrorMessage>
                    )}
                    {errors.userName?.type === 'pattern' && (
                      <ErrorMessage className="error-message--wrap">
                        {t('userNamePattern')}
                      </ErrorMessage>
                    )}
                  </div>

                  <div
                    className={`input-wrapper ${
                      isSsoUser ? 'input-wrapper-disabled' : ''
                    }`}
                  >
                    <Input
                      disabled={isSsoUser}
                      size="0"
                      width="100%"
                      fontSize="14px"
                      name="userRole"
                      marginBottom="0"
                      value={userRole}
                      readOnly
                    />

                    <label
                      htmlFor="userRole"
                      className={userRole.length > 0 ? 'disabledFillField' : ''}
                    >
                      {t('userRole')}*
                    </label>
                  </div>
                </InputsContainer>
              </ProfileContainer>
            </Box>
          )}
        </form>

        <ChangePassword />
        <MultiFactorAuth />
      </Box>

      <Box
        display={displayLoader ? 'flex' : 'none'}
        relative="true"
        minheightsize="450px"
        alignitems="center"
      >
        <LoaderWrap display={true} />
      </Box>
    </Box>
  );
};

export default ProfileDetails;

const ProfileImg = styled.img`
  width: 190px;
  height: 190px;
  display: ${props => props.display ?? 'white'};
`;

const ProfileContainer = styled(Box)`
  display: flex;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  gap: ${props => (props.isMobile ? '0' : '30px')};
`;

const InputsContainer = styled.div`
  margin-top: ${props => (props.isMobile ? '30px' : '0px')};
  width: ${props => !props.isMobile && '100%'};
`;
